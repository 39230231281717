import React from 'react';
import get from 'lodash/get';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { ChallengeCard } from './ChallengeCard';

import { classes, st } from './Widget.st.css';
import settingsParams from '../settingsParams';

import { EmptyList } from './EmptyList';

import { listenToHeightChanges } from 'wix-height-updater';
import { setWidthRestrictions } from '../../../services/resizeWindow';
import { withWixSDKWidget } from '../../../services/withWixSDK';
import {
  IWixSDKContext,
  useEnvironment,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { IChallengeListStateProps } from './interfaces';
import { withProviders } from '../../../contexts/main/withProviders';
import { withGeneralData } from '../../../contexts/GeneralDataProvider/withGeneralData';
import { withChallengesListData } from '../../../contexts/ChallengesListDataProvider/withChallengesListData';
import { withSettingsEvents } from '../../../contexts/SettingsEvents/SettingsEvents';
import { withLocation } from '../../../contexts/Location/withLocation';
import { withBI } from '../../../contexts/BI/withBI';
import { ButtonNames } from '../../../contexts/BI/interfaces';
import { Pages } from '../../../contexts/Location/LocationContext';
import { Spinner } from '../../../components-shared/Spinner';
import { getIsFullWidth } from '../../../selectors/platform';
import { withPaidPlans } from '../../../contexts/PaidPlans/paidPlansContext';
import { applyProviders } from '../../../services/applyProviders';
import {
  ParticipantState,
  V1ChallengeState,
} from '@wix/ambassador-challenge-service-web/types';
import userTypeHandlers from '../../../contexts/User/helpers/userTypeHandlers';
import { Challenges } from '../../../editor/types/Experiments';

export const ChallengeList: React.FC<
  IChallengeListStateProps & IWixSDKContext
> = (props) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isEditorLoaded, setIsEditorLoaded] = React.useState<boolean>(false);
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { isEditor, isMobile, dimensions } = useEnvironment();

  React.useEffect(() => {
    let removeResizeListener: () => void;

    if (isEditor && props.Wix) {
      removeResizeListener = setWidthRestrictions(props.Wix);
      listenToHeightChanges(props.Wix, window);
    }

    setIsEditorLoaded(props.editorLoaded);
    return () => {
      if (isEditor && props.Wix) {
        removeResizeListener();
      }
    };
  }, []);

  const toggleIsLoading = () => {
    setIsLoading(!isLoading);
  };

  const {
    challengesListData,
    buttonState,
    goToPage,
    userPaidPlans,
    memberWebAppButtonClick,
  } = props;

  const items = challengesListData?.memberChallenges || [];
  const filteredChallenges = items.filter((challenge) => {
    const participantState = get(
      challenge.summary,
      'participation.state',
    ) as ParticipantState;
    const isFinished =
      get(challenge, 'challenge.transitions[0].state') ===
      V1ChallengeState.FINISHED;
    const isParticipant = userTypeHandlers.isJoinedAlready(participantState);
    const currChallFromSettings =
      settings.get(settingsParams.challengesVisible)[challenge.challenge.id] ||
      settings.get(settingsParams.challengesVisible)[
        challenge.challenge.settings.description.title
      ];
    const isChallengeVisible =
      !settings.get(settingsParams.challengesVisible) ||
      typeof currChallFromSettings === 'undefined'
        ? true
        : !currChallFromSettings?.hidden;

    return (!isFinished || isParticipant) && isChallengeVisible;
  });

  const orderFromSettings =
    settings.get(settingsParams.challengesOrdering)?.order || [];

  const orderedChallenges = orderFromSettings.length
    ? [
        ...orderFromSettings
          .map((orderedChall: { id: string }) => {
            const ch = filteredChallenges.find((_ch) => {
              return _ch?.challenge?.id === orderedChall?.id;
            });

            if (!ch) {
              return;
            }

            return ch;
          })
          .filter((i) => !!i),
        ...filteredChallenges.filter((ch) => {
          const isInOrder = orderFromSettings.find(
            (i: { id: string }) => i.id === ch?.challenge?.id,
          );
          return isInOrder ? false : true;
        }),
      ]
    : filteredChallenges;

  if (!orderedChallenges?.length) {
    return <EmptyList />;
  }

  return (
    <div
      data-hook="challenge-list-wrapper"
      className={st(classes.root, { mobile: isMobile })}
    >
      {isLoading && (
        <div className={classes.loader}>
          <Spinner />
        </div>
      )}
      <ul
        data-hook="challenge-list"
        className={classes.wrapper}
        style={{
          gridTemplateColumns: `repeat(auto-fit, minmax(max(240px, calc(100% / ${
            (isMobile
              ? 1
              : (settings.get(settingsParams.itemsPerRow) as number)) + 1
          })), 1fr))`,
          gridGap: `${settings.get(settingsParams.cardSpacing)}px`,
          justifyItems:
            settings.get(settingsParams.itemsPerRow) === 1 || isMobile
              ? `center`
              : null,
        }}
      >
        {orderedChallenges.map((item, index) => {
          return (
            <li className={classes.wrapperItem} key={index}>
              {!!item && (
                <ChallengeCard
                  memberChallenge={item}
                  isFullWidth={getIsFullWidth(dimensions)}
                  goToPage={() => {
                    if (!isEditorLoaded) {
                      return;
                    }

                    const slug = experiments.enabled(
                      Challenges.enableSlugsInUrl,
                    )
                      ? item?.challenge?.settings?.seo?.slug
                      : item?.challenge?.id;

                    void memberWebAppButtonClick({
                      buttonName: ButtonNames.ViewChallenge,
                      challengeId: item?.challenge?.id,
                    });

                    toggleIsLoading();
                    goToPage({
                      pageId: Pages.Details,
                      challengeId: slug,
                      reloadPage: true,
                    });
                  }}
                  userPaidPlans={userPaidPlans}
                  buttonState={buttonState}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

ChallengeList.displayName = 'ChallengeList';

export default applyProviders(ChallengeList, [
  withProviders,
  withLocation,
  withSettingsEvents,
  withBI,
  withGeneralData,
  withChallengesListData,
  withPaidPlans,
  withWixSDKWidget,
]);
