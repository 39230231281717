import React from 'react';
import { NewCard } from 'wix-ui-tpa/NewCard';
import {
  Button,
  PRIORITY as ButtonPriority,
  SIZE as ButtonSize,
} from 'wix-ui-tpa/Button';

import { classes, st } from './ChallengeCard.st.css';
import { imageDataToMediaUrl } from '@wix/challenges-web-library/dist/src/helpers/image';
import { serverTimelineTypeToClientTimelineString } from '@wix/challenges-web-library/dist/src/formatters/serverClient/timelineType';
import { MemberChallenge } from '@wix/ambassador-challenge-service-web/types';
import { ChallengeBadge } from '../../Badge';
import { getCountPrefix } from '../../../../selectors/langCount';
import { serverToClientPricing } from '@wix/challenges-web-library/dist/src';
import {
  getDurationForFlexyTimelines,
  isPrivateChallenge,
} from '../../../../selectors/challenges';
import { ChallengeCardSubtitle } from './ChallengeCardSubtitle';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { Challenges } from '../../../../editor/types/Experiments';
import userTypeHandlers from '../../../../contexts/User/helpers/userTypeHandlers';

interface IChallengeCard {
  memberChallenge: MemberChallenge;
  buttonState: 'default' | 'hover';
  userPaidPlans: any;
  goToPage(params: any): void;
  isFullWidth: boolean;
}

export const ChallengeCard: React.FC<IChallengeCard> = (props) => {
  const [cardWidth, setCardWidth] = React.useState<number>(0);
  const settings = useSettings();
  const { experiments } = useExperiments();
  const { t } = useTranslation();
  const { language, isMobile } = useEnvironment();

  const cardRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    setCardWidth(cardRef?.current.offsetWidth || 0);
  }, []);

  React.useEffect(() => {
    if (cardRef?.current?.offsetWidth !== cardWidth) {
      setCardWidth(cardRef?.current.offsetWidth || 0);
    }
  });

  const {
    memberChallenge,
    userPaidPlans,
    goToPage,
    buttonState,
    isFullWidth,
  } = props;

  const { challenge, summary } = memberChallenge;
  const displayParams = {
    image: settings.get(settingsParams.displayImage),
    duration: settings.get(settingsParams.displayDuration),
    participants: settings.get(settingsParams.displayParticipants),
    divider: settings.get(settingsParams.displayDivider),
    price: settings.get(settingsParams.displayPrice),
  };

  const TitleTag = settings.get(settingsParams.challengeNameTag) || 'h2';
  const duration = getDurationForFlexyTimelines(challenge);
  const langPrefix = getCountPrefix(duration?.value || 0, language);

  const participantState = summary?.participation?.state;

  const showParticipantButton =
    experiments.enabled(Challenges.enableButtonTextSettings) &&
    userTypeHandlers.isTouchedChallenge(participantState);

  const durationString = serverTimelineTypeToClientTimelineString(
    challenge.settings.timelineType as any,
    language,
    t,
    'challenge-card.duration-string.ongoing',
    `challenge-card.duration-string.flexible.days${langPrefix}`,
    `challenge-card.duration-string.flexible.weeks${langPrefix}`,
    'challenge-card.duration-string.no-limit',
  );

  const pricingString = serverToClientPricing(
    t,
    challenge,
    userPaidPlans,
    'challenge-card.pricing.free',
    'challenge-card.pricing.paid.separator',
    'pricing.payment-option.subscription',
    'challenge.page.pricing-options.paid-general',
  );

  const showParticipants = !!(
    displayParams.participants &&
    challenge.participantsSummary.participantsNumber
  );
  const slug = experiments.enabled(Challenges.enableSlugsInUrl)
    ? challenge?.settings?.seo?.slug
    : challenge?.id;

  return (
    <NewCard
      className={st(classes.card, {
        textAlignment: settings.get(settingsParams.textAlignment),
        imageShape: settings.get(settingsParams.imageShape),
        cropSelection: settings.get(settingsParams.cropSelection),
        mobile: isMobile,
        buttonState,
        imageRatio: settings.get(settingsParams.imageRatio),
      })}
      stacked={true}
    >
      {displayParams.image && (
        <NewCard.Container className={classes.media}>
          <a
            href={`challenge-page/${slug}`}
            onClick={(e) => {
              e.preventDefault();
              goToPage(e);
            }}
          >
            <div className={classes.ratioBox}>
              {challenge.settings.description.media ? (
                <img
                  alt="Program"
                  data-hook="image-wrapper"
                  className={classes.imageWrapper}
                  src={imageDataToMediaUrl({
                    ...(challenge.settings.description.media.image as any),
                    width: 800,
                    height: 600,
                    quality: 100,
                  })}
                />
              ) : null}
            </div>
          </a>
        </NewCard.Container>
      )}
      <NewCard.Container className={classes.info}>
        <ChallengeBadge
          isPrivate={isPrivateChallenge(challenge)}
          summary={summary}
          challengeTransition={
            challenge.transitions['0'] && challenge.transitions['0'].state
          }
        />
        <div
          data-hook="info-wrapper"
          ref={cardRef}
          style={{ position: 'absolute', width: '100%' }}
        />
        <div className={classes.infoWrapper}>
          <a
            href={`challenge-page/${slug}`}
            onClick={(e) => {
              e.preventDefault();
              goToPage(e);
            }}
          >
            <TitleTag
              className={classes.title}
              data-hook="card-title"
              id={`id-${challenge.id}`}
            >
              {(challenge as any)?.shouldTranslateTitle
                ? t(challenge.settings.description.title)
                : challenge.settings.description.title}
            </TitleTag>
          </a>

          <ChallengeCardSubtitle
            participantsState={summary?.participation?.state}
            displayParams={displayParams}
            showParticipants={showParticipants}
            durationString={durationString}
            challenge={challenge}
          />
          {displayParams.divider && (
            <div data-hook="challenge-divider" className={classes.divider} />
          )}
          {displayParams.price && (
            <p data-hook="challenge-pricing" className={classes.pricing}>
              {pricingString}
            </p>
          )}
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            aria-label={`${t('challenge-card.view-button')} ${
              challenge.settings.description.title
            }`}
            data-hook="view-button"
            fullWidth={!(cardWidth > 300 || isFullWidth)}
            className={classes.button}
            priority={ButtonPriority.primary}
            size={ButtonSize.small}
            onClick={goToPage}
          >
            {settings.get(
              showParticipantButton
                ? settingsParams.buttonTextForParticipant
                : settingsParams.buttonText,
            )}
          </Button>
        </div>
      </NewCard.Container>
    </NewCard>
  );
};

ChallengeCard.displayName = 'ChallengeCard';
