import {
  ParticipantState,
  V1Challenge,
} from '@wix/ambassador-challenge-service-web/types';
import React from 'react';
import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { Challenges } from '../../../../editor/types/Experiments';
import { classes } from './ChallengeCard.st.css';

interface ChallengeSubtitleProps {
  displayParams: {
    duration: boolean;
    image: boolean;
    divider: boolean;
    price: boolean;
    participants: boolean;
  };
  showParticipants: boolean;
  durationString: string;
  challenge: V1Challenge;
  participantsState: ParticipantState;
}

export const ChallengeCardSubtitle: React.FC<ChallengeSubtitleProps> = ({
  displayParams,
  showParticipants,
  durationString,
  challenge,
  participantsState,
}) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const isHappyScreensEnabled = experiments.enabled(
    Challenges.enableHappyScreens,
  );

  if (
    !isHappyScreensEnabled &&
    [ParticipantState.COMPLETED, ParticipantState.FAILED].includes(
      participantsState,
    )
  ) {
    return (
      <p className={classes.subtitle} data-hook="card-subtitle">
        <span className={classes.subtitleItem} data-hook="challenge-duration">
          {t('challenge.finished')}
        </span>
      </p>
    );
  }

  return (
    (displayParams.duration || showParticipants) && (
      <p className={classes.subtitle} data-hook="card-subtitle">
        {!!displayParams.duration && (
          <span className={classes.subtitleItem} data-hook="challenge-duration">
            {durationString}
          </span>
        )}
        {displayParams.duration && showParticipants && (
          <span className={classes.subtitleItem} data-hook="challenge-bullet">
            <>&nbsp;&bull;&nbsp;</>
          </span>
        )}
        {!!showParticipants && (
          <span
            className={classes.subtitleItem}
            data-hook="challenge-participants"
          >
            {t(
              `challenge-card.${
                challenge.participantsSummary.participantsNumber > 1
                  ? 'participants_plural'
                  : 'participants'
              }`,
              {
                count: challenge.participantsSummary.participantsNumber,
              },
            )}
          </span>
        )}
      </p>
    )
  );
};
