import { createSettingsParams } from '@wix/yoshi-flow-editor/tpa-settings';

import {
  IChallengeSettings,
  TextAlignment,
  ImageShape,
  ImageRatio,
  CropSelection,
} from './Settings/challengeSettings/challengeSettings.types';

export default createSettingsParams<IChallengeSettings>({
  itemsPerRow: {
    getDefaultValue() {
      return 3;
    },
  },
  displayImage: {
    getDefaultValue() {
      return true;
    },
  },
  displayDuration: {
    getDefaultValue() {
      return true;
    },
  },
  displayParticipants: {
    getDefaultValue() {
      return true;
    },
  },
  displayDivider: {
    getDefaultValue() {
      return true;
    },
  },
  displayPrice: {
    getDefaultValue() {
      return true;
    },
  },
  textAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  cardSpacing: {
    getDefaultValue: () => 32,
  },
  imageShape: {
    getDefaultValue() {
      return ImageShape.Rectangle;
    },
  },
  imageRatio: {
    getDefaultValue() {
      return ImageRatio.HD;
    },
  },
  cropSelection: {
    getDefaultValue() {
      return CropSelection.Middle;
    },
  },
  // for visitor. do not change the key for backward compatibility
  buttonText: {
    getDefaultValue({ t }) {
      return t('challenge-card.join-button');
    },
  },
  buttonTextForParticipant: {
    getDefaultValue({ t }) {
      return t('challenge-card.view-button');
    },
  },
  challengesVisible: {
    getDefaultValue() {
      return {};
    },
  },
  challengesOrdering: {
    getDefaultValue() {
      return { order: [] };
    },
  },
  challengeNameTag: {
    getDefaultValue() {
      return 'h2';
    },
  },
  labelParticipant: {
    getDefaultValue({ t }) {
      return t('challenge.participant.joined');
    },
  },
  labelPendingRequest: {
    getDefaultValue({ t }) {
      return t('challenge.participant.join-requested');
    },
  },
  labelChallengeCompleted: {
    getDefaultValue({ t }) {
      return t('live-site.challenge-list.badge.completed');
    },
  },
  labelJoinRejected: {
    getDefaultValue({ t }) {
      return t('challenge.participant.join-rejected');
    },
  },
  labelJoinApproved: {
    getDefaultValue({ t }) {
      return t('challenge.participant.join-approved');
    },
  },
});
