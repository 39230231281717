export enum Challenges {
  enableMembersInvitation = 'specs.challenges.EnableMembersInvitation',
  showStepShare = 'specs.challenges.WebMemberShowStepShare',
  enableSlugsInUrl = 'specs.challenges.WebUseSlugsInURL',
  renderSeoTags = 'specs.challenges.WebEnableRenderSeoTags',
  isHiddenStepsEnabled = 'specs.challenges.OOIEnableHiddenAndLockSteps',
  muteErrorToastNotifications = 'specs.challenges.MuteErrorToastNotifications',
  enableConcurrentMode = 'specs.challenges.OOIEnableConcurrentMode',
  enableHappyScreens = 'specs.challenges.WebMemberHappyScreens',
  enableNewSidebarScrollingBehaviour = 'specs.challenges.WebNewSidebarScrollingBehaviour',
  enableFullscreenMode = 'specs.challenges.WebSidebarFullscreenMode',
  enableButtonTextSettings = 'specs.challenges.EnableEditorSPViewButtonSettings',
  enableServerless = 'specs.challenges.OOIEnableServerlessAPI',
  enableVisibilityByName = 'specs.challenges.OOIEnableVisibilityByName',
}
